export enum Pages {
  Editor = "editor",
  // Tasks = "tasks",
  // Challenges = "challenges",
  // Compare_Automata = "compare",
  // Request_A_Feature = "request",
  // Report_A_Problem = "report",
  Help = "help",
  Contact = "contact",
}
